import React, { useState } from "react";
import { Button, FormGroup, FormControl, ControlLabel } from "react-bootstrap";
//import "./Login.css";
var CryptoJS = require("crypto-js");

export default function Login(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  function validateForm() {
    return email.length > 0 && password.length > 0;
  }

  function handleSubmit(event) {
		event.preventDefault();
		_apiCall(email, password);
	}

	function handleLoginSuccess (res){
		if(res.response.auth){
			localStorage.token = res.response.auth.token;
			localStorage.uid = res.response.auth.uid;
		}
		console.dir(localStorage);
		//alert(res);
	}

	function handleCheckLoginSuccess (res){
		console.dir(res);
		//alert(res);
		window.location = '/chat.html';
	}

	function chat(){
		window.location = '/chat.html';
	}


	function handleLoginFail (res){
		console.log('fail', res);
	}

	function _serialize(obj) {
    return Object.keys(obj)
      .map(function(k) {
        return k + "=" + encodeURIComponent(obj[k]);
      })
      .join("&");
	}
	
	function checkLogin(){
		return fetch("https://api.rosterflow.com/test.php", {
      method: "post",
			body: _serialize({ action: "test"}),
			credentials: 'same-origin',
      headers: new Headers({
				'Authorization': 'Bearer '+localStorage.token,
				'content-type': 'application/x-www-form-urlencoded'
      })
    })
      .then(res => res.json())
      .then(handleCheckLoginSuccess, handleLoginFail);
	}
	
	
	function _apiCall(email, password){
		return fetch("https://api.rosterflow.com/auth.php", {
      method: "post",
			body: _serialize({ action: "login", email: email, password: password }),
			credentials: 'same-origin',
      headers: new Headers({
				'content-type': 'application/x-www-form-urlencoded'
      })
    })
      .then(res => res.json())
      .then(handleLoginSuccess, handleLoginFail);
	}
	//	xmlHttpRequest.setRequestHeader('Authorization', 'Bearer ' + jwtoken);

  return (
    <div className="Login">
      <form onSubmit={handleSubmit}>
        <FormGroup controlId="email" bsSize="large">
          <ControlLabel>Email</ControlLabel>
          <FormControl
            autoFocus
            type="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
        </FormGroup>
        <FormGroup controlId="password" bsSize="large">
          <ControlLabel>Password</ControlLabel>
          <FormControl
            value={password}
            onChange={e => setPassword(e.target.value)}
            type="password"
          />
        </FormGroup>
        <Button block bsSize="large" disabled={!validateForm()} type="submit">
          Login
        </Button>
      </form>
			<button onClick={checkLogin}>test</button>
			<button onClick={chat}>chat</button>
    </div>
  );
}