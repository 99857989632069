import React from "react";
import { Link } from "react-router-dom";

class SignUpButton extends React.Component {
	render() {
		return(
      <Link to="/signup">
				<button className="signupButton">{this.props.text}</button>
			</Link>
    );
  }


}

export default SignUpButton;