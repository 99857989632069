//const e = React.createElement;
import React from "react";
import { Link } from "react-router-dom";

class Footer extends React.Component {
  render() {
    //var props = this.props;
    return(
      <div className="footer">
        <ul>
          <li>
          	<Link to="/">Home</Link>
          </li>
          <li>
          	<Link to="/pricing">Pricing</Link>
          </li>
          <li>
          	<Link to="/about">About</Link>
          </li>
          <li>
          	<Link to="/privacy">Privacy</Link>
          </li>
          <li className="hidden">
          	<Link to="/login">Login</Link>
          </li>
        </ul>
      </div>
    );
  }


}

export default Footer;