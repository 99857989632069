//const e = React.createElement;
import React from "react";
import ReactGA from "react-ga";

class SignUpForm extends React.Component {
  constructor(props) {
		super(props);
    this.state = { value: "", email: false, emailFormError: false, signupFormDisplay: 'hide' };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.updateForm = this.updateForm.bind(this);
    this.updateFormError = this.updateFormError.bind(this);
    this.updateClass = this.updateClass.bind(this);
  }

  updateForm(res) {
    this.setState({
      email: res.response.email
    });
    ReactGA.event({
      category: "signup",
      action: "free trial"
    });
  }

  updateFormError(err) {
    this.setState({
      emailFormError: true
    });
  }

  serialize(obj) {
    return Object.keys(obj)
      .map(function(k) {
        return k + "=" + encodeURIComponent(obj[k]);
      })
      .join("&");
  }

  getCookie(name) {
    var key = name + "=",
      value = "; " + document.cookie;
    if (key.indexOf(name + "=") > -1) {
      var parts = value.split("; " + name + "=");
      if (parts.length === 2)
        return parts
          .pop()
          .split(";")
          .shift();
    }
    return false;
  }

  hide(e) {
    document.getElementById(e.currentTarget.dataset.id).style.display = "none";
    return true;
  }

  apiCall(e) {
    return fetch("https://rosterflow.com/api.php", {
      method: "post",
      body: this.serialize({ action: "signup", email: e }),
      headers: new Headers({
        "content-type": "application/x-www-form-urlencoded"
      })
    })
      .then(res => res.json())
      .then(this.updateForm, this.updateFormError);
  }

  validateEmail(input) {
    var re = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return re.test(input); //input.match(re);
  }

  handleChange(event) {
    this.setState({ value: event.target.value });
  }

  handleSubmit(event) {
    var valid = this.validateEmail(this.state.value);
    event.preventDefault();
    if (valid) {
      return this.apiCall(this.state.value);
      // change the view to indicate the data was accepted
    } else {
      // show something on the screen to indicate the email is not valid
    }
  }

	updateClass(){
		let signupFormDisplay = 'hide';
		if (window.scrollY > 400) {
			signupFormDisplay = 'show';
		}
		this.setState({signupFormDisplay: signupFormDisplay});
	}

  componentDidMount() {
    window.addEventListener('scroll', this.updateClass);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.updateClass);
  }

  render() {
    var display,
      errorMessage,
      signupCookie = this.getCookie("signup"),
      cookieEmail = !!signupCookie
        ? decodeURIComponent(signupCookie)
        : this.getCookie("signup"),
      email = this.state.email || cookieEmail;

    if (this.state.emailFormError) {
      errorMessage = "Network error. Please try again later.";
    }
    if (email) {
      display = (
        <div>
          <div>
            <button
              className="close-signup"
              data-id="signup-form"
              onClick={this.hide}
            >
              X
            </button>
          </div>
          <span className="message">Thanks!</span>
          <div className="advisory">
            Please look for the email we sent to <strong>{email}</strong>.
            <p>
              We are actively developing RosterFlow. When it's ready you will be
              among the first to get a chance to use it!
            </p>
          </div>
        </div>
      );
    } else {
      display = (
        <form onSubmit={this.handleSubmit}>
          <div>
            <span className="message">Let's get started!</span>
          </div>
          <div className="error-message">{errorMessage}</div>
          <label htmlFor="email">Email:</label>
          <input
            id="email"
						type="email"
						placeholder="Enter your address"
            value={this.state.value}
            onChange={this.handleChange}
          />
          <button type="submit">Get A Free Trial</button>
          <span>{this.state.email}</span>
        </form>
      );
    }

    return (
      <div className={'signupForm ' + this.state.signupFormDisplay} id="signup-form">
        {display}
      </div>
    );
  }
}

export default SignUpForm;
