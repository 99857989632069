//const e = React.createElement;
import React from "react";
//import Link from "./Link.js";
//import LoginForm from "./loginForm.js";
//import ProfileDisplay from "./profileDisplay.js";
//import fetchUtils from './utils2.js';

//import "./Header.css";

class Header extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      ply : "",
      loggedIn: false,
      profile: {}
    };
    this.login = this.login.bind(this);
    this.updateProfile = this.updateProfile.bind(this);
    // this.showLoginForm = this.showLoginForm.bind(this);
    // this.showCases = this.showCases.bind(this);
    // this.updateProfile = this.updateProfile.bind(this);
    // this.logout = this.logout.bind(this);
  }

  updateProfile(e){
    this.setState({
      loggedIn: e,
      profile: {
        nameFirst: e.name_first,
        nameLast: e.name_last
      }
    });
  }

  login(e){
    console.log('login triggered');
    e.preventDefault();
    // var params = {
    //   action: 'login',
    //   data: '',
    //   email: e.target.value
    // };

    
  };


  render() {
    //var loginFormOutput;
    // if(this.state.profile.nameFirst){
    //   loginFormOutput = <ProfileDisplay className="component profile" name={this.state.profile.nameFirst}></ProfileDisplay>
    // } else {
    //   loginFormOutput = <LoginForm profile={this.state.profile} login={this.login}></LoginForm>
    // }


    return(
      <div className="header">
        <h1>{this.props.title}</h1>
      </div>
    );
  }


}

export default Header;