import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import ReactGA from 'react-ga';
import "./App.css";
import PageTitle from "./components/PageTitle.js";
import Header from "./components/Header.js";
import Body from "./components/Body.js";
import Footer from "./components/Footer.js";
import ScrollToTop from "./components/ScrollToTop.js";

// import {Elements, StripeProvider} from 'react-stripe-elements';
// import CheckoutForm from './components/CheckoutForm.js';
// <Route path="/signup" component={pageSignup} />

function App() {
	ReactGA.initialize('UA-32964888-5');
	
	return (
    <Router>
			<ScrollToTop>
			<div className="App">
				<Header className="App-header" title="Roster Flow" />
        {/* makeAjaxCall={this.makeAjaxCall} updateProfile={this.updateProfile}
        <Body className="App-body" /> */}
        <Route exact path="/" component={pageContent} />
        <Route path="/about" component={pageContent} />
        <Route path="/pricing" component={pageContent} />
        <Route path="/privacy" component={pageContent} />
        <Route path="/signup" component={pageContent} />
        <Route path="/login" component={pageContent} />
      </div>
			</ScrollToTop>
    </Router>
  );

  // function pageSignup(e) {
	// 	return [
	// 		<StripeProvider key="0"
	// 			apiKey="pk_test_TYooMQauvdEDq54NiTphI7jx"
	// 			route={e.location.pathname}>
	// 				<div className="example">
	// 					<h1>Get started!</h1>
	// 					<p><strong>30-Day Free Trial: $0.00</strong><br />
	// 						14 days free, then $9.99/month</p>
	// 					<Elements>
	// 						<CheckoutForm />
	// 					</Elements>
	// 				</div>
	// 			</StripeProvider>,
	// 		<PageTitle key="1" route={e.location.pathname}/>
	// 	];
	// }
	
	function pageContent(e) {
		ReactGA.pageview(e.location.pathname);
		return [
			<Body key="0" route={e.location.pathname}/>,
			<Footer key="1" route={e.location.pathname}/>,
			<PageTitle key="2" route={e.location.pathname}/>
		];
  }


}

export default App;
