import React from 'react';
import Helmet from 'react-helmet';

function PageTitle({ route }) {
	var title,
	defaultTitle = 'Easily organize the players on your team | RosterFlow.com';
    return (
        <Helmet>
            <title>{title ? title : defaultTitle}</title>
        </Helmet>
    );
}

export default PageTitle;
